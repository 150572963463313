#map {
    position: absolute;
    top: 0;
    margin-left: 0px !important;
    margin-right: 0px!important;
    padding: 0 !important;
    bottom: 0;
    width: 100%;
    height: 90%;
  }
  
  .menu {
    position: relative;
    top: 90%;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #3F65A1, #3587C0, #5fB66C);
    z-index: 1;
  }
  
  .botones-menu {
    background-color: aliceblue;
    color: #3F65A1 !important;
    border-radius: 20px;
    border: 3px solid #3F65A1 !important;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 0.6rem;
  
  }
  
  .border-mga{
    border: 3px solid #5fB66C !important;
  }
  #inlineFrameExample {
    border-radius: 20px !important;
  }
  .border-radius-mga{
    border-radius: 20px !important;
  }
  .nav-link{
    color: #fff !important;
  }
  .nav-link:hover{
    color: #5fB66C !important;
  }
  
 .card{
   max-width: 100% !important;
 }  