@font-face {
  font-family: Dosis;
  src: local("Dosis-VariableFont_wght.ttf"),
    url(../fonts/Dosis/Dosis-VariableFont_wght.ttf);
  font-weight: normal;
  font-style: normal;
}


.App {
  text-align: center;
  max-width: 1000px;
  margin: auto;
  font-family: ‘Dosis‘, sans-serif;

}

.App h1 {
  font-size: calc(1.000rem + 1.5vw);
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;

}

.product-image {
  max-width: 400px;
}

.snipcart-checkout:hover {
  color: #3F51B5;
  text-decoration: underline;
}

.snipcart-add-item {
  background-color: #3F51B5;
  font-size: 18px;
  color: white;
  border: 0px;
  border-radius: 3%;
}

.snipcart-add-item:hover {
  box-shadow: 0px 3px 5px #888888;
}

.snipcart-add-item:active {
  box-shadow: 0 0 0 0;
}

.product-description {
  width: 400px;
  padding: 25px;
  margin: 25px;
  background-color: #CFD8DC;
  font-size: 20px;
}

.product-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.products {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;
}

.product:hover {
  background-color: #CFD8DC;
}

.navbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  margin-bottom: 1rem !important;
}

.masthead {
  margin-bottom: 0;
  background-image: linear-gradient(to right, #3F65A1, #3587C0, #5fB66C);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05), inset 0 -1px 0 rgba(0, 0, 0, .1);
  border-radius: 20px;
}

.masthead nav img {
  width: 135px;
  height: auto;
  margin-left: 3rem;
}

.card-img-top {
  padding-bottom: 1rem;
  width: 8rem;
  height: 8rem;
}

.card-img-top-1 {
  padding-bottom: 5rem;
  height: 13rem;
  width: 17.5rem;
}

a {
  color: #FFFFFF !important;
  ;
}

a:link {
  text-decoration: none;
  color: #FFFFFF !important;
}

.titulo-card {
  font-size: 1.0rem !important;
  text-decoration: none !important;
  color: white !important;
}

a.link-secondary:hover {
  color: #5ABC6D !important;
}

.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.marketing h2 {
  font-weight: 400;
}

.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

.featurette-divider {
  margin: 5rem 0;
}

.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -05rem;
}

#MenuDos {
  width: 18rem !important;
}

#cent {
  justify-content: center;
}

.card {

  display: block;
  background-color: white !important;
  border-top-left-radius: 1.25rem !important;
  border-top-right-radius: 1.25rem !important;
  padding-right: calc(var(--bs-gutter-x) * .0);
  padding-left: calc(var(--bs-gutter-x) * .0);
  border: solid 4px #3F65A1;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.card-2 {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 13rem;
  background-color: white !important;
  border-radius: 1.25rem !important;
  padding-right: calc(var(--bs-gutter-x) * .0);
  padding-left: calc(var(--bs-gutter-x) * .0);
  border: solid 4px #3F65A1;

}

.cart-web-color-A {
  background-color: #3F64A0 !important;
  height: 8px;
  width: 77%;
  float: left;
  margin-left: -4px;
  border-bottom-left-radius: 12px 12px;
}

.cart-web-color-B {
  background-color: #5ABC6D !important;
  height: 8px;
  width: 31.4%;
  margin-top: 0px;
  margin-left: 70%;
  border-bottom-right-radius: 12px 12px;
}

.call-center {
  background-image: url("../images/img/fondo-mga-0.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

.mapa-obras {
  background-image:
    url("../images/img/fondo-mga-0.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

.sem-mga {
  background-image:
    url("../images/img/fondo-mga-0.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}

.menu {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 5rem !important;
  border-radius: 20px !important;
  width: 100% !important;
}

.triangulo {
  position: relative;
  top: 32px;
  width: 0;
  height: 0;
  margin-left: 16px;
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-left: 0.8rem solid #3F64A0;
  margin-left: 14rem !important;
  z-index: 20;
}

.bunner-fc {
  position: relative;
  font-family: 'Dosis', sans-serif;
  height: 5rem !important;
  font-size: 1.5rem;
  background-color: #5FB66C !important;
  border-radius: 20px;
}

.bunner-fc-a {
  position: relative;
  top: -25px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background-image: linear-gradient(to right, #5fB66C, #3587C0, #3F65A1);
  color: #FFFFFF !important;
  width: 14.6rem;
  height: 5rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  border-right: 0.6rem solid #FFFFFF;
}

.bunner-fc-b {
  position: absolute;
  top: 10px;
  left: 245px;
  background-color: #3F65A1 !important;
  color: #FFFFFF !important;
  text-align: center;
  width: 4rem;
  height: 4rem;
  font-size: 2.5rem;
  border: 2px solid white;
  border-radius: 50%;
}

.bunner-fc-c {
  position: absolute;
  top: 0px;
  left: 335px;
  font-family: 'Dosis', sans-serif;
  font-weight: 900 !important;
  background-color: #5FB66C !important;
  color: hsl(0, 0%, 100%) !important;
  width: 8rem;
  height: 5rem;
  font-size: 1.2rem !;
  line-height: 1.6rem;
  border-right: 0.6rem solid #5FB66C;
}

.bunner-fc-d {
  position: absolute;
  top: 0px;
  left: 491px;
  background-color: #3F64A0 !important;
  color: #FFFFFF !important;
  text-align: center;
  width: 4rem;
  height: 4rem;
  font-size: 2.5rem;
  border: 2px solid white;
  border-radius: 50%;
}

.bunner-fc-e {
  position: absolute;
  top: 0px;
  left: 575px;
  background-color: #5FB66C !important;
  color: #FFFFFF !important;
  width: 9rem;
  float: left;
  height: 5rem;
  line-height: 1.6rem;
  border-right: 0.6rem solid #5FB66C;
}

.bunner-fc-f {
  position: absolute;
  top: 0px;
  left: 719px;
  background-color: #3F64A0 !important;
  color: #FFFFFF !important;
  text-align: center;
  width: 4rem;
  height: 4rem;
  font-size: 2.5rem;
  border: 2px solid white;
  border-radius: 50%;
}

.bunner-fc-g {
  position: absolute;
  top: 0px;
  left: 805px;
  background-color: #5FB66C !important;
  color: #FFFFFF !important;
  width: auto;
  float: left;
  margin-top: 0rem;
  margin-left: 0%;
  height: 5rem;
  line-height: 1.6rem;
  border-radius: 50%;
}


.titulo-card {
  font-size: 1.3rem !important;
  text-decoration: none !important;
  color: #3F65A1 !important;
  margin: 0.5rem;
}

.ms-nav-prev {
  border-radius: 50% !important;
}

.ms-nav-next {
  border-radius: 50% !important;
}

.formularios {
  border-radius: 20px;
  border: 3px solid #5FB66C;
  background-image: url("../images/img/fondo-MGA.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  text-align: center;
}

.footer-mga {
  margin-top: 1rem;
  font-family: 'Dosis', sans-serif !important;
  font-size: 0.8rem !important;
  border-radius: 20px;
  border: 3px solid #5FB66C;
  background-color: #3F64A0 !important;
  color: #FFFFFF !important;
  max-width: 1400px;
  text-align: left;
}

img,
svg {
  vertical-align: middle;
  width: 4rem;
}

.img-F {
  width: auto;
  height: 3rem;
}

.linea-f {
  position: relative;
  top: 3px;
  z-index: 1;
}

footer a:link {
  text-decoration: none;
  color: #FFFFFF;
}

footer a:visited {
  text-decoration: none;
  color: #FFFFFF;
}

footer a.link-secondary:hover {
  color: #5ABC6D !important;
}

.color-f {
  color: #FFFFFF;
}

footer h5 {
  font-size: 1.2rem !important;
  margin-left: -1rem;
}

.link-secondary {
  color: #FFFFFF !important;
}

.carousel-prev::before {
  background-image: url("../data/icons/chevron-back-outline.svg") !important;
}

.carousel-next::before {
  background-image: url("../data/icons/chevron-forward-outline.svg") !important;
}


.slide_img {
  border-top-right-radius: 12px 12px !important;
  border-top-left-radius: 12px 12px !important;
}

.silder-titulo {
  height: 5rem !important;
  background-color: #3587C0;
}

.button-Noticia {
  display: inline-block;
  padding: 0.5em 1em;
  background: #5ABC6D;
  color: white;
  border-radius: 2em;
  border: none;
  outline: 2px solid #5ABC6D;
  outline-offset: 3px;
}

.Noticia {
  height: 400px;
  border-radius: 20px;
  border: 3px solid #5FB66C;
  margin-top: 2rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  z-index: 1;
}

.noticia-centro {
  position: relative;
}

.ScrollNoticias {
  position: absolute;
  right: 0px;
  width: 50%;
  height: 300px;
  padding-left: 5px;
  overflow-y: scroll;
  z-index: 2;
  text-align: left;

}


.ImgNoticia {
  position: absolute;
  left: 0px;
  width: 50%;
  height: 320px;

}

.ImgNoticia img {
  height: 90%;
  width: auto;
  z-index: 2;
  border-radius: 20px;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #FFFFFF
}

*::-webkit-scrollbar-thumb {
  background-color: #3587C0;
  border-radius: 20px;
  border: 3px solid #FFFFFF;
}

.img-sem img {
  width: 255px !important;
}

.img-sem-m img {
  width: 449px !important;
}

.map-container {
  border-radius: 20px !important;
}

ul {
  list-style: none;
  margin-top: 1rem;
}

li {
  list-style: none;
  margin-top: 1rem;
}

.img-p-h-c img {
  width: 20px;
  fill: #5ABC6D !important;
}

.Car-secondary-1 {
  margin-top: 3rem !important;
  border-radius: 20px;
  border: 3px solid #5FB66C;
  margin-left: 2rem;
  margin-right: 2rem;
}

.img-intendente img {
  width: 35%;
  height: auto;
  border-radius: 20px;
  border: 3px solid #5FB66C;
}

#map-container {
  height: 300px !important;
  width: auto;
  margin: 0.5rem !important;
  border-radius: 20px !important;
  z-index: -1 !important;
}

.map-container {
  border-radius: 20px !important;
  z-index: 3 !important;
  margin-bottom: 10rem;
}

.animacion:hover {

  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

}

.animacion-2:hover {

  transform: scale(1.15);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

}

.img-secret {
  height: 8rem;
  width: auto;
}

.img-secret-logo {
  height: 4rem;
  width: auto;


}

.color-blanco h1 {
  color: #FFFFFF !important;
  font-size: 1.8rem !important;
}

.Car-secretarias {

  width: 97% !important;
  margin: auto !important;
}

.color-gobierno {
  background-color: rgba(95, 181, 108);
}

.color-obras-publicas {
  background-color: rgba(229, 0, 126);
}

.color-desarrollo-humano {
  background-color: rgba(114, 196, 238);
}

.color-turismo {
  background-color: rgba(59, 132, 137);
}

.color-produccion {
  background-color: rgba(240, 135, 0);
}

.color-salud {
  background-color: rgba(0, 174, 148);
}

.color-economia {
  background-color: rgba(0, 172, 148);
}

.color-de-politicas-de-genero {
  background-color: rgba(129, 63, 144);
}

.color-medio-amibiente {
  background-color: rgba(114, 181, 43);
}

.color-educacion {
  background-color: rgba(63, 101, 160);
}

.color-ciencia-y-tecnologia {
  background-color: rgba(231, 65, 69);
}

.color-de-derechos-humanos {
  background-color: rgba(114, 196, 238);
}